/* eslint-disable sonarjs/no-duplicate-string */
import {
  PHOTOGRAPHERS_TAXONOMY_KEY,
  PLANNERS_TAXONOMY_KEY,
  SearchableVendorTaxonomyKey,
  VIDEOGRAPHERS_TAXONOMY_KEY,
} from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import {
  InlineAccordion,
  InlineAccordionItemType,
} from '@zola/zola-ui/src/components/InlineAccordion/InlineAccordion';
import { Title2 } from '@zola/zola-ui/src/typography/Headings';
import { US_STATES } from 'libs/universal/utils/states';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import { WSearchLocationView } from '@zola/svc-web-api-ts-client';
import { getVendorWordCategory } from '@zola-helpers/client/dist/es/marketplace/vendorWords';
import { Link, List, Title, VendorCrossLinksContainer } from './VendorCrossLinks.styles';

type LocationAndCategory = {
  taxonomyKey: SearchableVendorTaxonomyKey;
  locationSlug: string;
  locationLabel: string;
};

/**
 * Static list of top ~50 location and category combinations
 *
 * @see https://docs.google.com/spreadsheets/d/14O_p3wYJzDE7dT7evtfy5SDhV5ZyZrUjzj41WQjONgA/edit
 */
const topLocationsAndCategories: LocationAndCategory[] = [
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'chicago-il',
    locationLabel: 'Chicago, IL',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'seattle-wa',
    locationLabel: 'Seattle, WA',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'las-vegas-nv',
    locationLabel: 'Las Vegas, NV',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'dallas-tx',
    locationLabel: 'Dallas, TX',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'tampa-fl',
    locationLabel: 'Tampa, FL',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'pittsburgh-pa',
    locationLabel: 'Pittsburgh, PA',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'los-angeles-ca',
    locationLabel: 'Los Angeles, CA',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'houston-tx',
    locationLabel: 'Houston, TX',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'denver-co',
    locationLabel: 'Denver, CO',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'los-angeles-ca',
    locationLabel: 'Los Angeles, CA',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'new-orleans-la',
    locationLabel: 'New Orleans, LA',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'chicago-il',
    locationLabel: 'Chicago, IL',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'kansas-city-mo',
    locationLabel: 'Kansas City, MO',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'minneapolis-mn',
    locationLabel: 'Minneapolis, MN',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'houston-tx',
    locationLabel: 'Houston, TX',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'tampa-fl',
    locationLabel: 'Tampa, FL',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'new-york-ny',
    locationLabel: 'New York, NY',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'austin-tx',
    locationLabel: 'Austin, TX',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'boston-ma',
    locationLabel: 'Boston, MA',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'miami-fl',
    locationLabel: 'Miami, FL',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'orlando-fl',
    locationLabel: 'Orlando, FL',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'dallas-tx',
    locationLabel: 'Dallas, TX',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'atlanta-ga',
    locationLabel: 'Atlanta, GA',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'cleveland-oh',
    locationLabel: 'Cleveland, OH',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'santa-barbara-ca',
    locationLabel: 'Santa Barbara, CA',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'long-island-ny',
    locationLabel: 'Long Island, NY',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'austin-tx',
    locationLabel: 'Austin, TX',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'denver-co',
    locationLabel: 'Denver, CO',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'fort-worth-tx',
    locationLabel: 'Fort Worth, TX',
  },
  {
    taxonomyKey: VIDEOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'dallas-tx',
    locationLabel: 'Dallas, TX',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'san-francisco-ca',
    locationLabel: 'San Francisco, CA',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'indianapolis-in',
    locationLabel: 'Indianapolis, IN',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'kansas-city-mo',
    locationLabel: 'Kansas City, MO',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'cincinnati-oh',
    locationLabel: 'Cincinnati, OH',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'detroit-mi',
    locationLabel: 'Detroit, MI',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'sacramento-ca',
    locationLabel: 'Sacramento, CA',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'baltimore-md',
    locationLabel: 'Baltimore, MD',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'new-york-ny',
    locationLabel: 'New York, NY',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'san-diego-ca',
    locationLabel: 'San Diego, CA',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'phoenix-az',
    locationLabel: 'Phoenix, AZ',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'honolulu-hi',
    locationLabel: 'Honolulu, HI',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'atlanta-ga',
    locationLabel: 'Atlanta, GA',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'boston-ma',
    locationLabel: 'Boston, MA',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'minneapolis-mn',
    locationLabel: 'Minnepolis, MN',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'portland-or',
    locationLabel: 'Portland, OR',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'san-diego-ca',
    locationLabel: 'San Diego, CA',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'seattle-wa',
    locationLabel: 'Seattle, WA',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'milwaukee-wi',
    locationLabel: 'Milwaukee, WI',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'cape-cod',
    locationLabel: 'Cape Cod',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'grand-rapids-mi',
    locationLabel: 'Grand Rapids, MI',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'indianapolis-in',
    locationLabel: 'Indianapolis, IN',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'las-vegas-nv',
    locationLabel: 'Las Vegas, NV',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'philadelphia-pa',
    locationLabel: 'Philadelphia, PA',
  },
];

const getStateItem = (type: 'venues' | 'vendors'): InlineAccordionItemType => {
  let category = 'Wedding Vendors';
  let slug = 'vendors';

  if (type === 'venues') {
    category = 'Wedding Venues';
    slug = 'venues';
  }

  return {
    title: (
      <Title2 css={Title} presentation="h6">
        {category} by State
      </Title2>
    ),
    content: (
      <ul css={List}>
        {US_STATES.map(state => {
          const url = `/wedding-vendors/states/${state.slug}/${slug}`;
          return (
            <li key={state.slug}>
              <LinkV2 css={Link} href={url} noTextTransform subtle>
                {state.name} {category}
              </LinkV2>
            </li>
          );
        })}
      </ul>
    ),
  };
};

const getTopCitiesItem = (
  type: 'venues' | 'vendors',
  topLocations: WSearchLocationView[]
): InlineAccordionItemType => {
  let category = 'Wedding Vendors';
  let slug = '';

  if (type === 'venues') {
    category = 'Wedding Venues';
    slug = 'wedding-venues';
  }

  return {
    title: (
      <Title2 css={Title} presentation="h6">
        {category} in Top Cities
      </Title2>
    ),
    content: (
      <ul css={List}>
        {topLocations.map(location => {
          const searchSlug = [location.slug, slug].filter(Boolean).join('--');
          const url = `/wedding-vendors/search/${searchSlug}`;
          return (
            <li key={location.slug}>
              <LinkV2 css={Link} href={url} noTextTransform subtle>
                {category} in {location.label}
              </LinkV2>
            </li>
          );
        })}
      </ul>
    ),
  };
};

const getTopCitiesAndCategoriesItem = (): InlineAccordionItemType => {
  return {
    title: (
      <Title2 css={Title} presentation="h6">
        Wedding Vendors in Top Cities
      </Title2>
    ),
    content: (
      <ul css={List}>
        {topLocationsAndCategories.map((locationAndCategory, index) => {
          const { taxonomyKey, locationSlug, locationLabel } = locationAndCategory;
          const category = getVendorWordCategory(taxonomyKey);
          const url = `/wedding-vendors/search/${locationSlug}--${taxonomyKey}`;
          return (
            <li key={index}>
              <LinkV2 css={Link} href={url} noTextTransform subtle>
                Wedding {category} in {locationLabel}
              </LinkV2>
            </li>
          );
        })}
      </ul>
    ),
  };
};

interface VendorCrossLinksProps {
  topLocations: WSearchLocationView[];
}

export const VendorCrossLinks = (props: VendorCrossLinksProps) => {
  const { topLocations } = props;
  const items = [getStateItem('venues'), getStateItem('vendors')];

  if (topLocations.length > 0) {
    items.push(getTopCitiesItem('venues', topLocations));
    items.push(getTopCitiesAndCategoriesItem());
  }

  return (
    <VendorCrossLinksContainer>
      <InlineAccordion items={items} />
    </VendorCrossLinksContainer>
  );
};
